import { useEffect } from 'react';

import ProfileCard from '../components/ProfileCard';
const riderData = require('../data/profile');
// const riderData = [];

const Profiles = () => {
  useEffect(() => {
    document.title = 'Police Unity Tour Scotland - Team Profiles';
  }, []);

  const profiles = riderData.map((item, i) => (
    <ProfileCard key={i} rand={i} rider={item} />
  ));

  return (
    <>
      <h1 className="text-2xl md:text-4xl font-bold text-blue-900">
        Police Unity Tour Scotland.
      </h1>
      <h2 className="text-xl md:text-3xl font-bold text-blue-900">
        Team Profiles.
      </h2>
      <div
        id="profiles"
        className="mt-6 grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3"
      >
        {profiles}
      </div>
    </>
  );
};

export default Profiles;
