module.exports = [
  {
    image: '43_square',
    alt: '2 riders after receiving their medals',
    size: 'medium',
    shape: 'square',
    title: '2 riders receive their tour medals',
  },
  {
    image: '30_square',
    alt: 'Group of smiling riders at the road side',
    size: 'medium',
    shape: 'square',
    title: '',
  },
  {
    image: '134_long',
    alt: '5 riders walking towards the police college in the evening sun',
    size: 'large',
    shape: 'square',
    title: '',
  },
  {
    image: '13_tall',
    alt: 'Sticker on riders handlebars that reads Shut Up Legs',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '46_square',
    alt: 'A tour medal from the 2021 Police Unity Tour',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '45_square',
    alt: 'A celebratory beer at end of last big ride day',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '35_square',
    alt: '2 riders stuffing their faces, as usual',
    size: 'medium',
    shape: 'square',
    title: '',
  },
  {
    image: '59_tall',
    alt: 'Selfie with riders at start of 2018 tour',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '65_tall',
    alt: 'A very sleepy rider, asleep in a chair at the hotel',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '70_long',
    alt: 'Smiley rider and support crew',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '34_square',
    alt: '2 riders in wet weather gear on day 2 of 2021 tour, still smiling',
    size: 'large',
    shape: 'square',
    title: '',
  },
  {
    image: '44_tall',
    alt: '2 riders, who are also survivors, receive their medals at end of 2021 tour',
    size: 'medium',
    shape: 'tall',
    title: '',
  },
  {
    image: '37_square',
    alt: 'A wet and tired rider at the lunch stop day 2 of the 2021 tour',
    size: 'large',
    shape: 'square',
    title: '',
  },
  {
    image: '66_long',
    alt: 'The peleton having a quick stop on the 2018 tour',
    size: 'medium',
    shape: 'square',
    title: '',
  },
  {
    image: '69_long',
    alt: 'Keep the noise down. Rider in minibus on way home from 2018 tour',
    size: 'medium',
    shape: 'square',
    title: '',
  },
  {
    image: '73_tall',
    alt: 'One of the rider with the survivors family at the 2018 tour',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '71_tall',
    alt: 'Holding the Scotland Chapter sign at Drayton Manor',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '58_tall',
    alt: '2 riders at top of the Shap in 2018, looking very happy it is over',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '62_long',
    alt: '2 happy riders at start of 2018 tour in Gretna',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '6_square',
    alt: 'One of the bands that riders wear on the tour',
    size: 'medium',
    shape: 'square',
    title: '',
  },
  {
    image: '8_square',
    alt: 'Close up of a tour medal from 2018',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '104_tall',
    alt: 'Close up of riders band',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '63_long',
    alt: '3 riders enjoying cycling in the sun 2018 tour',
    size: 'large',
    shape: 'square',
    title: '',
  },
  {
    image: '77_tall',
    alt: 'A young survivor looking resplendent in his police uniform',
    size: 'medium',
    shape: 'tall',
    title: '',
  },
  {
    image: '40_square',
    alt: '4 riders in a serious discussion about tyres by the look of it',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '105_tall',
    alt: 'One of the riders trusty steeds',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '87_tall',
    alt: 'Close up of one riders bike computer',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '128_tall',
    alt: 'Ride marshall just after hard day in the saddle',
    size: 'small',
    shape: 'square',
    title: '',
    position: 'object-top',
  },
  {
    image: '118_long',
    alt: 'Gathering of riders, survivors, and friends at the arboretum 2021',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '99_tall',
    alt: 'One of the survivors who rode with us in 2021',
    size: 'large',
    shape: 'square',
    title: '',
    position: 'object-top',
  },
  {
    image: '114_long',
    alt: 'Peleton on the road',
    size: 'medium',
    shape: 'square',
    title: '',
  },
  {
    image: '113_long',
    alt: 'Peleton on the road',
    size: 'medium',
    shape: 'tall',
    title: '',
  },
  {
    image: '88_tall',
    alt: 'Rider medal from 2021',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '92_long',
    alt: 'Couple of riders at the gathering point 2021',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '96_tall',
    alt: 'Riders at the hotel',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '109_tall',
    alt: 'One of the riders bikes',
    size: 'small',
    shape: 'square',
    title: '',
    position: 'object-top',
  },
  {
    image: '103_tall',
    alt: 'Rider before the start of the ride 2021',
    size: 'medium',
    shape: 'square',
    title: '',
  },
  {
    image: '98_long',
    alt: '',
    size: 'medium',
    shape: 'square',
    title: '',
  },
  {
    image: '90_long',
    alt: 'Riders relaxing in a pub at a morning break in the tour',
    size: 'large',
    shape: 'square',
    title: '',
  },
  {
    image: '123_tall',
    alt: 'Wreath at the Cumbria Constabulary plaque',
    size: 'small',
    shape: 'square',
    title: '',
    position: 'object-bottom',
  },
  {
    image: '115_long',
    alt: 'Gathering of riders, survivors, and friends at the arboretum 2021',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '116_long',
    alt: 'Gathering of riders, survivors, and friends at the arboretum 2021',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '120_tall',
    alt: '3 smiley riders at finish point 2021',
    size: 'medium',
    shape: 'square',
    title: '',
  },
  {
    image: '22_tall',
    alt: 'Rider medal from 2018',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '24_long',
    alt: 'Riders gather for the briefing before start of 2021 tour',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '19_long',
    alt: 'Morning briefing before setting off on 2018 tour',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '41_square',
    alt: 'Riders joking around at finish of 2021 tour',
    size: 'large',
    shape: 'square',
    title: '',
  },
  {
    image: '32_tall',
    alt: 'Presentation of FUD top to rider on day 1 on 2021 tour',
    size: 'medium',
    shape: 'tall',
    title: '',
  },
  {
    image: '56_tall',
    alt: 'One of the riders and his family on the 2018 tour',
    size: 'large',
    shape: 'tall',
    title: '',
  },
  {
    image: '61_tall',
    alt: 'Rider at the top of the Shap 2018',
    size: 'large',
    shape: 'square',
    title: '',
  },
  {
    image: '72_tall',
    alt: 'Rider meeting family of fallen officer',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '79_tall',
    alt: '3 riders on 2021 tour',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '47_square',
    alt: 'Presentation of FUD top to a rider on last day of tour 2021',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '38_square',
    alt: '2 riders at the lunch stop on day 2 of 2021 tour',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '28_square',
    alt: 'Riders gather at the top of the Shap on the 2021 tour',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '106_tall',
    alt: '2 happy cyclists on 2021 tour',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '16_square',
    alt: '2 happy riders at top of the Shap 2018',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '2_long',
    alt: 'Riders heading towards Kendal after climbing the Shap on 2017 tour',
    size: 'medium',
    shape: 'square',
    title: '',
  },
  {
    image: '42_square',
    alt: 'Riders gather at Drayton Manor on 2021 tour',
    size: 'medium',
    shape: 'square',
    title: '',
  },
  {
    image: '33_square',
    alt: 'Quick food stop for riders on a wet day 2 on the 2021 tour',
    size: 'large',
    shape: 'square',
    title: '',
  },
  {
    image: '31_square',
    alt: '2 riders on wet day 2 2021',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '53_long',
    alt: 'Riders gathered at the Scotland border sign on 2018 tour',
    size: 'large',
    shape: 'square',
    title: '',
  },
  {
    image: '14_tall',
    alt: 'Arty shot of cycling and blue sky on 2018 tour',
    size: 'medium',
    shape: 'tall',
    title: '',
  },
  {
    image: '15_lang',
    alt: 'Rider relaxing under tree in the village of Shap before the big climb on 2018 tour',
    size: 'medium',
    shape: 'long',
    title: '',
  },
  {
    image: '12_long',
    alt: 'Rider meeting with family of fallen officer on the 2017 tour',
    size: 'small',
    shape: 'long',
    title: '',
  },
  {
    image: '1_long',
    alt: '2 riders at start of first ever Scotland Chapter tour in 2017',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '27_square',
    alt: '2 happy riders on day 1 of 2021 tour',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '108_long',
    alt: 'Rider meeting with family of fallen officer on the 2021 tour',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '122_tall',
    alt: 'Rider receiving award from the chapter lead on the 2021 tour',
    size: 'small',
    shape: 'square',
    title: '',
    position: 'object-top',
  },
  {
    image: '17_tall',
    alt: 'Riders heading towards Kendal after the Shap climb on the 2018 tour',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '26_square',
    alt: '2 happy riders on day 1 of 2021 tour',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '10_tall',
    alt: 'Riders enjoying a pint or two at end of 2017 tour',
    size: 'small',
    shape: 'square',
    title: '',
    position: 'object-top',
  },
  {
    image: '39_tall',
    alt: 'Rider showing off his FUD top on day 2 of the 2021 tour',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '130_tall',
    alt: 'Rider selfie while cycling along showing riders behind him',
    size: 'large',
    shape: 'square',
    title: '',
    position: 'object-right-bottom',
  },
  {
    image: '133_tall',
    alt: '3 riders taking a selfie with huge grins',
    size: 'medium',
    shape: 'square',
    title: '',
  },
  {
    image: '121_long',
    alt: 'Riders from the Scotland Chapter stand with the Chief Constable 2021',
    size: 'large',
    shape: 'square',
    title: '',
  },
  {
    image: '85_long',
    alt: 'Traffic officer migrates towards fellow species to introduce himself',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '94_tall',
    alt: 'Group of riders at end of 2021 tour',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '18_long',
    alt: 'Quick stop for riders on the 2018 tour',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '20_long',
    alt: 'Riders by the roadside on 2018 tour',
    size: 'medium',
    shape: 'square',
    title: '',
  },
  {
    image: '29_long',
    alt: 'Riders by the roadside on the 2021 tour',
    size: 'medium',
    shape: 'square',
    title: '',
  },
  {
    image: '52_long',
    alt: 'Riders by the memorial at the Scottish Police College before the 2018 tour starts',
    size: 'large',
    shape: 'square',
    title: '',
  },
  {
    image: '9_long',
    alt: 'Riders gather to listen to the speeches at Drayton Manor on the 2018 tour',
    size: 'large',
    shape: 'square',
    title: '',
  },
  {
    image: '4_long',
    alt: 'Rider heading toward Kendal on 2017 tour',
    size: 'large',
    shape: 'square',
    title: '',
  },
  {
    image: '54_long',
    alt: 'Riders gather at top of the Shap on the 2018 tour',
    size: 'medium',
    shape: 'square',
    title: '',
  },
  {
    image: '67_long',
    alt: 'Scottish Chapter riders at Drayton Manor on the 2018 tour',
    size: 'medium',
    shape: 'square',
    title: '',
  },
  {
    image: '21_tall',
    alt: 'Rider takes a selfie with fellow riders in the background on the 2018 tour',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '7_long',
    alt: 'Riders about to start the mass ride to Drayton Manor 2018',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '3_long',
    alt: 'Riders heading toward Kendal after climbing the Shap 2017',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '89_tall',
    alt: 'Roses placed at the Grampian Police plaque in 2021',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '124_tall',
    alt: 'Couple of riders enjoying a coffee at end of 2021 tour',
    size: 'large',
    shape: 'square',
    title: '',
  },
  {
    image: '132_tall',
    alt: 'Riders on the road in torrential rain on day 1 of the 2017 tour',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '129_tall',
    alt: '1 happy cyclist having conquered the Shap in 2021',
    size: 'medium',
    shape: 'square',
    title: '',
  },
  {
    image: '110_long',
    alt: 'The peleton on day 3 in Tamworth 2021',
    size: 'medium',
    shape: 'square',
    title: '',
  },
  {
    image: '83_long',
    alt: '3 riders at meeting point 2021',
    size: 'large',
    shape: 'square',
    title: '',
  },
  {
    image: '25_long',
    alt: 'Group of riders having a quick break on day 1 2021',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '74_long',
    alt: 'Riders and friends at evening meal 2018',
    size: 'large',
    shape: 'square',
    title: '',
  },
  {
    image: '84_long',
    alt: 'Riders waiting for departure of mass ride 2021',
    size: 'medium',
    shape: 'square',
    title: '',
  },
  {
    image: '86_long',
    alt: 'Riders waiting for departure of mass ride 2021',
    size: 'large',
    shape: 'square',
    title: '',
  },
  {
    image: '82_long',
    alt: 'Riders enjoying a coffee at the meeting point 2021',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '91_tall',
    alt: 'Riders waiting for departure of mass ride 2021',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '112_long',
    alt: 'The group in the mass ride to Drayton Manor 2021',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '117_long',
    alt: 'Riders at Drayton Manor 2021',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '64_long',
    alt: 'Photobombing 2018',
    size: 'large',
    shape: 'square',
    title: '',
    position: 'object-left',
  },
  {
    image: '78_long',
    alt: 'Support and ride marshalls in deep discussion 2021',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '97_long',
    alt: '2 riders during a quick break 2021',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '55_long',
    alt: 'The Scottish Chapter riders at end of day 2 2021',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '107_tall',
    alt: 'American police car',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '100_long',
    alt: 'Riders at top of the Shap 2021',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '102_long',
    alt: 'Peleoton riding through a town 2021',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '80_long',
    alt: 'Group of riders at meeting point 2021',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '81_long',
    alt: 'Another chapter riding into the meeting point 2021',
    size: 'large',
    shape: 'square',
    title: '',
  },
  {
    image: '93_long',
    alt: 'Riders at meeting point 2021',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '125_long',
    alt: 'Happy smiles 2017',
    size: 'medium',
    shape: 'square',
    title: '',
    position: 'object-right',
  },
  {
    image: '101_long',
    alt: '3 riders at the top of the Shap 2021',
    size: 'medium',
    shape: 'square',
    title: '',
    position: 'object-right',
  },
  {
    image: '50_tall',
    alt: 'The group waiting at traffic lights in the rain 2017',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '57_long',
    alt: 'The 2018 riders outside Gretna police office before the start of the tour',
    size: 'medium',
    shape: 'square',
    title: '',
  },
  {
    image: '126_long',
    alt: 'Riders waiting for departure of mass ride 2021',
    size: 'medium',
    shape: 'square',
    title: '',
  },
  {
    image: '111_long',
    alt: 'Riders waiting for departure of mass ride 2021',
    size: 'medium',
    shape: 'square',
    title: '',
    position: 'object-left',
  },
  {
    image: '127_long',
    alt: 'Riders climbing the Shap 2021',
    size: 'medium',
    shape: 'square',
    title: '',
    position: 'object-left',
  },
  {
    image: '75_long',
    alt: 'Riders and families gathered at the arboretum 2018',
    size: 'large',
    shape: 'square',
    title: '',
  },
  {
    image: '48_long',
    alt: 'Riders waiting for departure of mass ride 2021',
    size: 'medium',
    shape: 'square',
    title: '',
  },
  {
    image: '51_long',
    alt: 'The bands worn by the riders',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '49_long',
    alt: 'Riders and families at the service 2021',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '5_long',
    alt: 'Support vehicle feeding the riders coffee and tea on a cold and wet day 2017',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '11_long',
    alt: 'Riders waiting for departure of mass ride',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '23_long',
    alt: 'Riders waiting for departure of mass ride',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '60_long',
    alt: 'Scotish Chapter riders about to head out of Gretna 2018',
    size: 'small',
    shape: 'square',
    title: '',
  },
  {
    image: '131_long',
    alt: 'The 6 riders of the inaugural Scottish Chapter tour 2017',
    size: 'small',
    shape: 'square',
    title: '',
  },
];
