module.exports = [
  {
    riderName: 'Scott McCreadie',
    position: 'Chapter Lead',
    ridingFor: 'Alan McMurray',
    eow: '04/02/2006',
    image: '/images/profile/scott_mccreadie',
    sponsorName: 'Scott',
    justGiving: '',
    bio: ``,
  },
  {
    riderName: 'Ewan Fraser',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '/images/profile/ewan_fraser',
    sponsorName: 'Ewan',
    justGiving: 'https://www.justgiving.com/page/ewan-fraser-1730154187798',
    bio: ``,
  },
  {
    riderName: 'Jiles Alcock',
    position: 'Rider',
    ridingFor: 'John Alcock',
    eow: '07/10/2017',
    image: '/images/profile/jiles',
    sponsorName: 'Jiles',
    justGiving: 'https://www.justgiving.com/page/jiles-alcock-1730205320600',
    bio: ``,
  },
  {
    riderName: 'Mark McFern',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '/images/profile/mark_mcfern',
    sponsorName: 'Mark',
    justGiving: 'https://www.justgiving.com/page/mark-mcfern-1730313639573',
    bio: ``,
  },
  {
    riderName: 'Mike Quinn',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '/images/profile/mike',
    sponsorName: 'Mike',
    justGiving: 'https://www.justgiving.com/page/mike-quinn-1730462849634',
    bio: ``,
  },
];
