import { useState, useRef } from 'react';
import { useIntersection } from './intersectionObserver';

const GalleryImage = ({
  load = true,
  size = 'small',
  shape = 'square',
  image,
  alt = '',
  title = '',
  position = 'object-cemter',
  openPhoto,
}) => {
  const [isInView, setIsInView] = useState(false);
  const imgRef = useRef();

  useIntersection(imgRef, () => {
    setIsInView(true);
  });

  const imagePath = '/images/medium/';
  const postFix = '_medium';

  return (
    <div ref={imgRef} className={`outerItem w-full h-full ${size} ${shape}`}>
      <div className="w-full h-full overflow-hidden">
        {isInView ? (
          <picture>
            <source
              srcSet={`
              ${imagePath}/webp/${image}${postFix}.webp
            `}
            />
            <img
              src={`${imagePath}${image}${postFix}.jpg`}
              alt={alt}
              className={`${
                load ? `visible` : 'invisible'
              } galleryImage ${position}`}
              onClick={() => openPhoto(image)}
            />
          </picture>
        ) : (
          <div className="w-full h-full bg-blue-400 opacity-30"></div>
        )}
      </div>
    </div>
  );
};

export default GalleryImage;
