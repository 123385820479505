import { useEffect } from 'react';
import Countdown from 'react-countdown';
import { Link } from 'react-router-dom';

import FaqList from '../components/FaqList';
import UnityHero from '../images/unity_hero.webp';
import SponsorHero from '../images/sponsor_hero.webp';
import Contact from '../components/Contact';
import Sponsor from '../components/Sponsors';

const CountdownRender = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <span>And they're off!!</span>;
  } else {
    return (
      <span>
        <span className="block mb-2 text-2xl">Tour begins in:</span>
        <span className="border-2 border-blue-900 py-0 px-2 rounded-lg text-gray-100 bg-brand-countdown">
          {days.toString().padStart(2, '0')}
        </span>
        <span className="text-2xl"> days </span>
        <span className="border-2 border-blue-900 py-0 px-2 rounded-lg text-gray-100 bg-brand-countdown">
          {hours.toString().padStart(2, '0')}
        </span>
        <span className="text-2xl"> hours </span>
        <span className="border-2 border-blue-900 py-0 px-2 rounded-lg text-gray-100 bg-brand-countdown">
          {minutes.toString().padStart(2, '0')}
        </span>
        <span className="text-2xl">
          {' '}
          {minutes !== 1 ? 'minutes' : 'minute'}
        </span>
      </span>
    );
  }
};

const Main = () => {
  useEffect(() => {
    document.title = 'Police Unity Tour Scotland - Home';
  }, []);

  return (
    <>
      <div className="flex gap-2 items-center">
        <div className="w-full lg:w-1/2">
          <h1 className="text-2xl md:text-4xl font-bold text-blue-900">
            Police Unity Tour Scotland.
          </h1>

          {process.env.REACT_APP_FULL_UP === 'true' ||
          process.env.REACT_APP_NOTIFY_INTEREST === 'true' ? (
            <h2 className="mt-6 text-xl md:text-3xl font-bold text-blue-900">
              <Countdown
                date="2025-07-31T08:30:00"
                renderer={CountdownRender}
              />
            </h2>
          ) : process.env.REACT_APP_PRE_REGISTER === 'true' ? (
            <p className="max-w-prose leading-relaxed font-semibold text-blue-900 text-lg">
              Registration will open on Friday 29 November 2024. Please check
              back here on that date.
            </p>
          ) : (
            <h2 className="text-xl md:text-3xl font-bold text-blue-900">
              Join us on the 2025 ride.
            </h2>
          )}
          {process.env.REACT_APP_FULL_UP === 'true' ? (
            <>
              <p className="mt-6 max-w-prose leading-relaxed text-blue-900">
                We are on the countdown to the start of the event. We realise
                that times are tough just now but we would be grateful for even
                the smallest of donations. If you can help us out in supporting
                Care of Police Survivors then visit the{' '}
                <Link to="/profiles" className="font-semibold text-orange-700">
                  Profiles page
                </Link>{' '}
                and sponsor a rider.
              </p>
            </>
          ) : process.env.REACT_APP_PRE_REGISTER === 'true' ? null : (
            <>
              <p className="mt-6 max-w-prose leading-relaxed text-blue-900">
                The 2025 ride is due to take place from Thursday 31 July 2025
                until Sunday 3 August 2025. If you would like to join us then
                click on the register button below.
              </p>
              <p className="mt-6 max-w-prose leading-relaxed text-blue-900">
                This year every rider will need to create their own Just Giving
                page <span className="font-semibold text-lg">before</span>{' '}
                registering. You can do this{' '}
                <a
                  href="https://www.justgiving.com/campaign/put2025"
                  target="_blank"
                  rel="noreferrer"
                  className="font-semibold text-orange-700 underline"
                >
                  here
                </a>{' '}
                and click the "Start Fundraising" button. Once you have done
                this come back here and click on the "register" button.
              </p>
            </>
          )}

          <div className="flex sm:flex-col sm:items-center md:flex-row mt-6 space-x-5 sm:space-x-0 sm:space-y-3 md:space-x-5 md:space-y-0">
            {process.env.REACT_APP_FULL_UP === 'true' ||
            process.env.REACT_APP_PRE_REGISTER === 'true' ? null : (
              <a
                href="https://ukpoliceunitytour.org/scotland/"
                target="_blank"
                rel="noreferrer"
                className="inline-block sm:w-40 sm:text-center md:w-auto px-6 py-2 rounded-xl shadow-lg border-2 border-blue-800  bg-blue-800 hover:bg-blue-700 hover:border-blue-700 focus:outline-none focus:ring focus:ring-offset-2 focus:ring-blue-800 focus:ring-opacity-50 active:bg-blue-900 transform transition text-white tracking-wider font-semibold"
              >
                Register
              </a>
            )}
          </div>
        </div>
        {process.env.REACT_APP_FULL_UP === 'true' ? (
          <div className="hidden sm:block lg:w-1/2">
            <div
              className="w-96 h-96 bg-no-repeat rounded-2xl shadow-xl bg-cover"
              style={{
                backgroundImage: `url("${SponsorHero}")`,
              }}
            ></div>
          </div>
        ) : (
          <div className="hidden sm:block lg:w-1/2">
            <div
              className="w-96 h-96 bg-no-repeat rounded-2xl shadow-xl bg-cover"
              style={{
                backgroundImage: `url("${UnityHero}")`,
              }}
            ></div>
          </div>
        )}
      </div>
      <FaqList />
      <Sponsor />
      <Contact />
    </>
  );
};

export default Main;
