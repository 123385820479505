const RouteMap = (props) => {
  return (
    <div className="mt-6 sm:mt-0 relative h-60 w-60 justify-self-center">
      <picture>
        <source srcSet={`${props.routeImage}.webp`} />
        <img
          src={`${props.routeImage}.jpg`}
          alt=""
          className="absolute rounded-lg mix-blend-luminosity"
        />
      </picture>

      <div className="absolute h-52 w-52 inset-4 bg-blue-600 bg-opacity-20 overflow-hidden rounded-full shadow-lg border-2 border-blue-900"></div>
    </div>
  );
};

export default RouteMap;
