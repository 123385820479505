import { useEffect } from 'react';
import { ExternalLinkIcon } from '@heroicons/react/solid';

import StravaLogo from '../images/tour/strava.svg';
import GarminLogo from '../images/tour/garminlogo.svg';
import KomootLogo from '../images/tour/komoot.svg';
import RouteMap from '../components/RouteMap';

const Tour = () => {
  useEffect(() => {
    document.title = 'Police Unity Tour Scotland - Routes';
  }, []);

  return (
    <>
      <h1 className="text-2xl md:text-4xl font-bold text-blue-900">
        Police Unity Tour Scotland.
      </h1>
      <h2 className="text-xl md:text-3xl font-bold text-blue-900">
        The routes.
      </h2>
      <div className="mt-6 grid gap-4 grid-cols-1 sm:grid-cols-3">
        <RouteMap routeImage="/images/tour/day1_route" />
        <div className="col-span-2 text-blue-900">
          <h3 className="text-xl md:text-2xl font-bold text-blue-900">
            Day 1 - Gretna to Lancaster
          </h3>
          <p className="mt-4">
            Day 1 sees us leaving Gretna, after the briefing, at around 09:30 as
            one group. We head towards Carlisle then Penrith, as riders find
            their feet the group leaders will decide how best to split the
            group.
          </p>
          <p className="mt-2">
            Depending on numbers we may split into 3 or 4 groups. From Penrith
            we will head to the village of Shap for our lunch stop, giving us
            the chance to rest up and refuel for the biggest climb of the day.
          </p>
          <p className="mt-2">
            Today is the day with the most climbing, and within a couple of
            miles of the lunch stop is the biggest climb of the day, The Shap,
            topping out at 1400 ft. After a stop at the top to regroup, take
            photos, or get more sustenance it is time for the awesome 10 mile
            descent towards Kendal.
          </p>
          <p className="mt-2">
            With 20 miles to go we continue south towards Lancaster with the
            knowledge that the big climb is behind us and a well earned meal and
            drink lie ahead.
          </p>
          <p className="mt-6 tracking-wide">
            Distance:{' '}
            <span className="font-semibold text-orange-700">76 miles</span>.
            Elevation Gain:{' '}
            <span className="font-semibold text-orange-700">3,921 feet</span>.
          </p>
          <p className="mt-2">
            <a
              href="https://www.strava.com/routes/3113943573667635256"
              target="_blank"
              rel="noreferrer"
              className="text-yellow-600 font-semibold"
            >
              <img src={StravaLogo} className="inline-block w-6 h-6" alt="" />
              Get route from Strava
              <ExternalLinkIcon className="inline-block w-6 h-6" />
            </a>
          </p>
          <p className="mt-2">
            <a
              href="https://connect.garmin.com/modern/course/176090548"
              target="_blank"
              rel="noreferrer"
              className="text-blue-800 font-semibold"
            >
              <img src={GarminLogo} className="inline-block w-6 h-6" alt="" />
              Get route from Garmin
              <ExternalLinkIcon className="inline-block w-6 h-6" />
            </a>
          </p>
          <p className="mt-2">
            <a
              href="https://www.komoot.com/tour/1208122967?ref=wtd"
              target="_blank"
              rel="noreferrer"
              className="text-green-800 font-semibold"
            >
              <img src={KomootLogo} className="inline-block w-6 h-6" alt="" />{' '}
              Get route from Komoot
              <ExternalLinkIcon className="inline-block w-6 h-6" />
            </a>
          </p>
        </div>
        <RouteMap routeImage="/images/tour/day2_route" />
        <div className="col-span-2 text-blue-900">
          <h3 className="text-xl md:text-2xl font-bold text-blue-900">
            Day 2 - Lancaster to Stafford
          </h3>
          <p className="mt-4">
            Day 2, the longest day, arguably though an easier day than day 1 as
            there is a lot less climbing today. Very much a rolling terrain sort
            of day.
          </p>
          <p className="mt-2">
            After breakfast there will be a briefing, usually around 08:00,
            aiming to set off before 08:30. Leaving Lancaster we head south
            towards Preston, after Preston we continue south to our normal break
            location at around mile 40. Dependent on weather the pub there will
            normally offer us free coffee, or ice cream.
          </p>
          <p className="mt-2">
            From there we continue south through Leigh, continue over the
            Manchester Ship Canal with its 12&#189; pence toll bridge (we
            haven't had to pay yet). The lunch stop comes at mile 60.
          </p>
          <p className="mt-2">
            After a good break at the lunch stop it is time to finish off the
            last 40 miles. Heading south toward Stoke-on-Trent, from there it is
            a mere 15 miles to go to Stafford.
          </p>
          <p className="mt-2">
            For many of you this will be the longest ride you have ever done. A
            well earned meal, drink, and rest await you. The hard work is now
            behind you, tomorrow is the shortest day of the tour.
          </p>
          <p className="mt-6 tracking-wide">
            Distance:{' '}
            <span className="font-semibold text-orange-700">104 miles</span>.
            Elevation Gain:{' '}
            <span className="font-semibold text-orange-700">3,482 feet</span>.
          </p>
          <p className="mt-2">
            <a
              href="https://www.strava.com/routes/3113943803554528312"
              target="_blank"
              rel="noreferrer"
              className="text-yellow-600 font-semibold"
            >
              <img src={StravaLogo} className="inline-block w-6 h-6" alt="" />
              Get route from Strava
              <ExternalLinkIcon className="inline-block w-6 h-6" />
            </a>
          </p>
          <p className="mt-2">
            <a
              href="https://connect.garmin.com/modern/course/176090777"
              target="_blank"
              rel="noreferrer"
              className="text-blue-800 font-semibold"
            >
              <img src={GarminLogo} className="inline-block w-6 h-6" alt="" />
              Get route from Garmin
              <ExternalLinkIcon className="inline-block w-6 h-6" />
            </a>
          </p>
          <p className="mt-2">
            <a
              href="https://www.komoot.com/tour/1208120742?ref=wtd"
              target="_blank"
              rel="noreferrer"
              className="text-green-800 font-semibold"
            >
              <img src={KomootLogo} className="inline-block w-6 h-6" alt="" />{' '}
              Get route from Komoot
              <ExternalLinkIcon className="inline-block w-6 h-6" />
            </a>
          </p>
        </div>
        <RouteMap routeImage="/images/tour/day3_route" />
        <div className="col-span-2 text-blue-900">
          <h3 className="text-xl md:text-2xl font-bold text-blue-900">
            Day 3 - Stafford to Tamworth
          </h3>
          <p className="mt-4">
            Today is the shortest and flatest route of the tour. This will feel
            like a breeze in comparison to the previous 2 days. A later start is
            the order of the day with a briefing at around 09:00, aiming to set
            off by 09:30.
          </p>
          <p className="mt-2">
            From Stafford we head south east toward Rugeley and our obligatory
            McDonald's stop at mile 14. From there we continue through Lichfield
            and on toward Tamworth.
          </p>
          <p className="mt-2">
            Normally we make good time and will get a couple of hours rest
            before setting off to the meeting point for all the tour riders from
            the other chapters.
          </p>
          <p className="mt-2">
            After meeting up with the other chapters we will set off en masse
            for a few miles to Drayton Manor where we will have the opportunity
            to meet with the families of the officer we have ridden in memory of
            and to receive our tour medals.
          </p>
          <p className="mt-2"></p>
          <p className="mt-2"></p>
          <p className="mt-6 tracking-wide">
            Distance:{' '}
            <span className="font-semibold text-orange-700">32 miles</span>.
            Elevation Gain:{' '}
            <span className="font-semibold text-orange-700">1,300 feet</span>.
          </p>
          <p className="mt-2">
            <a
              href="https://www.strava.com/routes/3113943898805592508"
              target="_blank"
              rel="noreferrer"
              className="text-yellow-600 font-semibold"
            >
              <img src={StravaLogo} className="inline-block w-6 h-6" alt="" />
              Get route from Strava
              <ExternalLinkIcon className="inline-block w-6 h-6" />
            </a>
          </p>
          <p className="mt-2">
            <a
              href="https://connect.garmin.com/modern/course/176090944"
              target="_blank"
              rel="noreferrer"
              className="text-blue-800 font-semibold"
            >
              <img src={GarminLogo} className="inline-block w-6 h-6" alt="" />
              Get route from Garmin
              <ExternalLinkIcon className="inline-block w-6 h-6" />
            </a>
          </p>
          <p className="mt-2">
            <a
              href="https://www.komoot.com/tour/1208116635?ref=wtd"
              target="_blank"
              rel="noreferrer"
              className="text-green-800 font-semibold"
            >
              <img src={KomootLogo} className="inline-block w-6 h-6" alt="" />{' '}
              Get route from Komoot
              <ExternalLinkIcon className="inline-block w-6 h-6" />
            </a>
          </p>
        </div>
        <div className="sm:col-span-2 sm:col-start-2">
          <h3 className="text-xl md:text-2xl font-bold text-blue-900">
            Day 4 - Ride to the memorial service
          </h3>
          <p className="mt-4 text-blue-900">
            On the morning of the memorial service we again meet up with the
            other chapters to ride to the National Arboretum. This is a short
            ride of around 12 miles.
          </p>
        </div>
      </div>
    </>
  );
};

export default Tour;
