module.exports = [
  {
    question: 'Who can take part?',
    answer: `
<p>Membership is open to Police Officers (serving, retired or specials) or from the extended police family (PCSO's, Police Staff or DDO's etc). Applications from the wider Police family (COPFS etc) will be considered on a case by case basis. Participants must be 18 years and older.</p><br>
<p>The UK PUT is also open to survivors of a police officer killed in the line of duty to participate as a rider if they meet all other criteria.  Support team members may be non-police personnel and are typically police staff, family or trusted friends.</p><br>
<p>No one who is under suspension (with or without pay), under criminal investigation or subject to any other disciplinary procedures may participate.</p><br>
<p>All applicants MUST abide by the Police Code of Conduct and Regulations, the policies and rules of the UK Police Unity Tour.</p>
  `,
  },
  {
    question: 'What are the dates of the ride?',
    answer: `
<p>Care of Police Survivors have announced that their 2025 Memorial Service will take place at the National Memorial Arboretum, Staffordshire on Sunday 3 August 2025.</p><br>
<p>Therefore the UK Police Unity Tour, now in its twelfth year, will start on the Thursday, 31 July 2025 and arrive to coincide with the memorial service on the Sunday.</p>
    `,
  },
  {
    question: 'How much does it cost?',
    answer: `
<p>Each member, whether a participating rider or support staff, must pay £195 to register. This is a non-refundable deposit paid at the time of completing the online booking.</p><br>
<p>In addition, each rider commits to raise a minimum of £400 sponsorship for Care of Police Survivors.</p>
    `,
  },
  {
    question: 'Where does my fee go?',
    answer: `
<p>The registration fee will be used to offset the cost of three nights hotel accommodation, transportation and one UK Police Unity Tour jersey.</p>
    `,
  },
  {
    question: 'What equipment do I need?',
    answer: `
<ul class="pl-10 list-disc">
<li>A quality road or hybrid bicycle is recommended.</li>
<li>A BSI (Kite Mark) certified helmet which must be worn at all times whilst riding.</li>
<li>Suitable gloves and glasses, if preferred.</li>
<li>Black cycling shorts for uniformity.</li>
</ul>
    `,
  },
  {
    question: 'How far is the route?',
    answer: `
<p>The Scottish Chapter ride is one of the longer rides of the Unity Tour. Day 1 is around 78 miles, day 2 is 102 miles, and day 3 is a relatively shorter 32 miles to the rendevous point.</p><br>
<a href="/tour"><span class="inline-block text-lg font-semibold text-orange-700">See out Routes page for more details on what to expect.</span></a>
    `,
  },
  {
    question: 'Where do we start from?',
    answer: `
<p>The start point is Gretna, we usually meet at 08:30 on the first day ready to set off around 09:30. You can either make your own way to Gretna or we endevour to arrange pick ups at central locations in Edinburgh and Glasgow.</p>
    `,
  },
  {
    question: 'How do I get home?',
    answer: `
<p>We provide mini-buses and bike transport from the National Arboterum to Gretna, and onward travel for those that arrived in Gretna on our arranged transport.</p>
    `,
  },
  {
    question: 'How fit do I have to be?',
    answer: `
<p>The most important thing to remember is that this is a tour and not a race. In the past we have had people of all abilities taking part. The support vehicles are with us at all times, we have regular breaks, and if the need arises you can always jump in the support vehicle.</p>
    `,
  },
  {
    question: 'Can friends and family come to the service?',
    answer: `
<p>The rememberance service is open to everyone. Serving officers may wish to attend in uniform however this not compulsory.</p>
    `,
  },
];
