const Contact = () => {
  return (
    <div className="w-full pt-10">
      <h2 id="contactus" className="text-3xl font-bold text-blue-900">
        Contact Us
      </h2>
      <div className="w-full px-2 py-6 mt-6 mx-auto bg-blue-100 text-blue-900">
        <p>
          You can contact us by emailing the Chapter Lead at{' '}
          <a
            href="mailto:chapterlead@unitytour.scot"
            className="text-orange-700 font-semibold tracking-wider"
          >
            chapterlead@unitytour.scot
          </a>
        </p>
      </div>
    </div>
  );
};

export default Contact;
