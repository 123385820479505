import { useEffect } from 'react';
import { ExternalLinkIcon } from '@heroicons/react/solid';

const Terms = () => {
  useEffect(() => {
    document.title = 'Police Unity Tour Scotland - Terms and Conditions';
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <div className="text-blue-900">
      <h1 className="text-2xl md:text-4xl font-bold text-blue-900">
        Police Unity Tour Scotland.
      </h1>
      <h2 className="text-xl md:text-3xl font-bold text-blue-900">
        Terms &amp; Conditions.
      </h2>
      <h3 className="mt-6 text-xl font-semibold">Requirements of Riders</h3>
      <p className="mt-2 max-w-3xl">
        To raise a minimum of £400 for COPS. You must create your own fund
        raising page on Just Giving before registering, and use your fund
        raising page to receive donations.
      </p>
      <p className="mt-4 max-w-3xl">
        To fully comply with{' '}
        <a
          href="https://ukpoliceunitytour.org/"
          target="_blank"
          rel="noreferrer"
          className="text-orange-700 font-semibold"
        >
          UK PUT rules
          <ExternalLinkIcon className="inline-block w-6 h-6" />
        </a>
        and{' '}
        <a
          href="https://www.scotland.police.uk/about-us/code-of-ethics-for-policing-in-scotland/"
          target="_blank"
          rel="noreferrer"
          className="text-orange-700 font-semibold"
        >
          Police Code of Ethics
          <ExternalLinkIcon className="inline-block w-6 h-6" />
        </a>
      </p>
      <p className="mt-4 max-w-3xl">
        Attendance at the UK COPS service of remembrance on Sunday the 3rd of
        August 2025 at the National Memorial Arboretum in Alrewas,
        Staffordshire.
      </p>
      <h3 className="mt-6 text-xl font-semibold">
        UK Police Unity Tour (Scottish Chapter)
      </h3>
      <p className="mt-2 max-w-3xl">
        All participants enter this event entirely at their own risk and the
        Chapter organisers shall not be liable for any injury or loss that might
        occur as a result of their participation. It is recommended that all
        riders ensure they have suitable insurance to cover any damage to their
        bikes or themselves that may occur during the period of the UK Police
        Unity Tour.
      </p>
      <p className="mt-4 max-w-3xl">
        All participants understand that the Chapter organisers will hold and
        use data provided by them in connection with UK Police Unity Tour
        (Scottish Chapter) administration, to keep them informed of its
        activities and for occasional fundraising appeals.
      </p>
      <p className="mt-4 max-w-3xl">
        By taking part, all participants confirm that they are happy for any
        photographs taken during their participation to be used to publicise the
        UK Police Unity Tour cycle event.
      </p>
      <p className="mt-4 max-w-3xl">
        Any participant unsure of their physical ability to take part in the UK
        Police Unity Tour must take medical advice from a general practitioner
        prior to the event.
      </p>
      <p className="mt-4 max-w-3xl">
        All participants must notify the Chapter Lead by email at{' '}
        <a
          href="mailto:chapterlead@unitytour.scot"
          className="text-orange-700 font-semibold tracking-wider"
        >
          chapterlead@unitytour.scot
        </a>{' '}
        details of any health problems or medication prior to the start of the
        event.
      </p>
      <p className="mt-4 max-w-3xl">
        All participants confirm that their bicycles are roadworthy, in good
        repair, and suitable for use in the Unity Tour. Random checks will be
        made to ensure your cycle is roadworthy.
      </p>
      <p className="mt-4 max-w-3xl">
        All participants agree to abide by the rules of the highway code, and
        follow the advice, direction and instruction of the Chapter Lead and
        Ride Marshals to ensure a safe and successful event. Failure to do so
        may lead to you being removed from the Unity Tour.
      </p>
      <p className="mt-4 max-w-3xl">
        A BSI (Kite Mark) certified cycle helmet must be worn at all times when
        riding and the wearing of gloves and eye protection is also recommended.
        (Exemptions are applied for those who wear religious dress as part of
        their faith).
      </p>
      <p className="mt-4 max-w-3xl">
        Support vehicles will be available throughout the duration of the cycle
        and any rider who is instructed to make use of the support vehicle by
        the Chapter Lead or Ride Marshals for any reason including fatigue,
        injury, or mechanical fault with their bike, must do so.
      </p>
      <p className="mt-4 max-w-3xl">
        All participants agree that they may be refused the right of
        participation in the UK Police Unity Tour where Chapter organisers
        believe the participant's safety or the safety of others may be
        compromised.
      </p>
      <p className="mt-4 max-w-3xl">
        UK Police Unity Tour cancellation: If the UK Police Unity Tour cycle is
        cancelled or delayed due to unforeseen circumstances our entire
        liability in respect of all costs and expenses you may incur as a result
        of such cancellation or delay will be the entry price you have paid to
        take part in the event.
      </p>
    </div>
  );
};

export default Terms;
