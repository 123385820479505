import { Routes, Route } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import Main from './pages/Main';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Gallery from './pages/Gallery';
import Notfound from './pages/Notfound';
import Tour from './pages/Tour';
import Profiles from './pages/Profiles';

function App() {
  return (
    <>
      <Header />
      <main className="flex-grow mt-6 px-4">
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/tour" element={<Tour />} />
          <Route path="/profiles" element={<Profiles />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
}

export default App;
