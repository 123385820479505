import { useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import UnityLogo from '../images/unity.svg';
import Hamburger from '../images/hamburger.svg';
import Close from '../images/close.svg';

const Header = () => {
  const [navOpen, setNavOpen] = useState(false);

  return (
    <header className="flex justify-between items-center px-4 text-blue-900">
      <Link to="/">
        <div className="flex items-center">
          <img
            src={UnityLogo}
            className="h-12 w-12"
            alt="Police Unity Tour Logo"
          />
          <h2 className="pl-6 text-xl font-semibold">Scottish Chapter</h2>
        </div>
      </Link>
      <button
        className="absolute block bg-cover bg-no-repeat bg-origin-border w-10 h-10 top-2 right-2 z-50 md:hidden"
        style={{
          backgroundImage: `url("${navOpen ? Close : Hamburger}")`,
        }}
        aria-controls="primary-navigation"
        aria-expanded={navOpen}
        onClick={() => setNavOpen(!navOpen)}
      >
        <span className="sr-only">Menu</span>
      </button>
      <nav>
        <ul
          id="primary-navigation"
          className={`flex flex-col fixed inset-0 left-[30%] text-lg font-semibold py-20 px-8 z-10 space-y-4 bg-blue-100 bg-opacity-70 backdrop-filter backdrop-blur-2xl transform ${
            navOpen ? `translate-x-0` : `translate-x-[30rem]`
          } transition duration-500 ease-out md:py-0 md:px-0 md:static md:flex-row md:space-x-2 lg:space-x-6 md:space-y-0 md:bg-transparent md:backdrop-blur-none md:translate-x-0`}
        >
          <li className="px-1 rounded hover:bg-blue-200">
            <Link to="/" onClick={() => setNavOpen(false)}>
              Home
            </Link>
          </li>
          <li className="px-1 rounded hover:bg-blue-200">
            <Link to="/tour" onClick={() => setNavOpen(false)}>
              Routes
            </Link>
          </li>
          <li className="px-1 rounded hover:bg-blue-200">
            <Link to="/gallery" onClick={() => setNavOpen(false)}>
              Gallery
            </Link>
          </li>
          <li className="px-1 rounded hover:bg-blue-200">
            <Link to="/profiles" onClick={() => setNavOpen(false)}>
              Profiles
            </Link>
          </li>
          <li className="px-1 rounded hover:bg-blue-200">
            <HashLink to="/#contactus" onClick={() => setNavOpen(false)}>
              Contact Us
            </HashLink>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
